import React, { useEffect, useState } from "react";
import { ArtifactResource, ReleaseResource } from "../api/models";
import vscodeIcon from "../icons/vscode.svg";
import windowsIcon from "../icons/windows.svg";
import macIcon from "../icons/macos.svg";
import linuxIcon from "../icons/linux.svg";
interface ArtifactProps {
  artifact: ArtifactResource;
  release: ReleaseResource;
  onDownload: (releaseId: string, artifactId: string) => Promise<string>;
}

const ArtifactComponent: React.FC<ArtifactProps> = ({
  artifact,
  release,
  onDownload,
}) => {
  const [icon, setIcon] = useState("");
  const handleArtifactDownload = async () => {
    const url = await onDownload(release.id, artifact.id);
    window.open(url);
  };
  var getIcon = function () {
    switch (artifact.attributes.platform) {
      case "windows":
        return <img height={"50px"} width={"50px"} src={windowsIcon} />;
      case "osx":
        return <img height={"50px"} width={"50px"} src={macIcon} />;
      case "osx-arm64":
        return <img height={"50px"} width={"50px"} src={macIcon} />;
      case "osx-x64":
        return <img height={"50px"} width={"50px"} src={macIcon} />;
      case "linux":
        return <img height={"50px"} width={"50px"} src={linuxIcon} />;
    }
  };
  return (
    <tr>
      <td className="file-icon">{getIcon()}</td>
      <td className="file-name">{artifact.attributes.filename}</td>
      <td>
        <button className="download-button" onClick={handleArtifactDownload}>
          Download
        </button>
      </td>
    </tr>
  );
};

export default ArtifactComponent;
