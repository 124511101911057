import axios, { AxiosInstance } from "axios";

export default class ApiService {
  private apiClient: AxiosInstance;
  private accountId: string;
  private token: string;

  constructor(accountId: string, token: string) {
    this.apiClient = axios.create({
      baseURL: "https://api.keygen.sh",
    });
    this.accountId = accountId;
    this.token = token;
  }

  async get(endpoint: string) {
    const response = await this.apiClient.get(endpoint, {
      headers: {
        Authorization: `License ${this.token}`,
        Accept: "application/vnd.api+json",
      },
    });
    return response.data;
  }

  async getReleases() {
    return this.get(`/v1/accounts/${this.accountId}/releases`);
  }

  async getArtifacts(releaseId: string) {
    return this.get(
      `/v1/accounts/${this.accountId}/releases/${releaseId}/artifacts`
    );
  }

  async downloadArtifact(releaseId: string, artifactId: string) {
    const response = await this.apiClient.get(
      `/v1/accounts/${this.accountId}/releases/${releaseId}/artifacts/${artifactId}`,
      {
        headers: {
          Authorization: `License ${this.token}`,
          Accept: "application/vnd.api+json",
          Prefer: "no-redirect",
        },
        maxRedirects: 0,
      }
    );
    const redirectUrl = response.data.data.links.redirect;
    return redirectUrl;
  }
}
