import React, { useState, useEffect } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
interface CodeSnippetProps {
  text: string;
}

const CodeSnippet: React.FC<CodeSnippetProps> = ({ text }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const handleCopyToClipboard = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <div className="code-snippet">
      <div className="code-section">
        <CopyToClipboard text={text} onCopy={handleCopyToClipboard}>
          <span>{copied ? "Copied!" : <MdContentCopy />}</span>
              </CopyToClipboard>{" "}
              <br></br>
        <pre>{text}</pre>
      </div>
    </div>
  );
};

export default CodeSnippet;
