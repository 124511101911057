import React from "react";
import { ReleaseResource } from "../api/models";

interface ReleaseDropdownProps {
  releases: ReleaseResource[] | undefined | null;
  onSelectRelease: (release: ReleaseResource) => void;
}

const ReleaseDropdown: React.FC<ReleaseDropdownProps> = ({
  releases,
  onSelectRelease,
}) => {
  if (!releases) {
    return <div>No releases to display.</div>;
  }

  const handleSelectRelease = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const releaseId = event.target.value;
    const selectedRelease = releases.find(
      (release) => release.id === releaseId
    );
    if (selectedRelease) {
      onSelectRelease(selectedRelease);
    }
  };

  return (
    <div>
      <label htmlFor="release-select">Select a release:</label>
      <select name="release" id="release-select" onChange={handleSelectRelease}>
        {releases.map((release) => {
          return (
            <option key={release.id} value={release.id}>
              {release.attributes.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default ReleaseDropdown;
