import React, { ReactNode, useEffect, useState } from "react";
import ApiService from "../api/KeygenApi";
import { ArtifactResource, ReleaseResource } from "../api/models";
import ArtifactComponent from "./ArtifactEntry";
import ReleaseDropdown from "./ReleaseDropdown";
import "./ArtifactList.css";
import { JsxElement } from "typescript";

interface ArtifactListProps {
  title: string;
  description: ReactNode ;
  artifacts: ArtifactResource[];
  release: ReleaseResource;
  logo: string;
}

const ArtifactList: React.FC<ArtifactListProps> = ({
  artifacts,
  release,
  title,
  description,
  logo
}) => {
  return (
    <div className="box-component">
      <h2 className="box-title">
        <img src={logo} alt="Icon" className="title-icon" />
        {title}
      </h2>
      <p className="box-description">{description}</p>

      <table>
        <tbody>
          {artifacts.map((artifact) => {
            return (
              <ArtifactComponent
                key={artifact.id}
                artifact={artifact}
                release={release!}
                onDownload={() => {
                  const api = new ApiService(
                    "52c049c7-8aea-46d9-ba83-df77baf1a006",
                    window.location.hash.split("#")[1]
                  );
                  return api.downloadArtifact(release!.id, artifact.id);
                }}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ArtifactList;
